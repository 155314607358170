<template>
    <div class="page">
        <div class="page-header pd">
            <sc-button icon="chevron-left" @click="$router.push(`/app/${resource.route}/index`)">Back</sc-button>
        </div>
        <div class="pd">
            <sc-form :form="form" @submit="submit()" v-if="form"></sc-form>
        </div>
    </div>

</template>

<script>
export default {
  name: "new",

  props: {
    passed: {
      required: true,
    }
  },

  data() {
    return {
      words: '',
      form: null,
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.form = this.$sform.createForm({
      name: `New ${this.resource.name}`,
      url: this.resource.api,
      fields: {
        name: '',
        description: '',
        frameset_id: {
          name: 'Frameset',
          component: 'nr-select',
          options: {
            empty: true,
            data: this.fetchResource('framesets?amount=1000')
          }
        },
      },
      success: () => {
        this.$notify.success(`${this.resource.name} added`)
        this.$router.push(`/app/${this.resource.route}/index`)
      }
    })
  },

  methods: {
    submit() {
      this.form.post()
        .then(res => {
          this.$router.push(`${res.data.data.id}/edit`)
        });
    },
    fetchResource(resource) {
      return this.$talker.api(resource)
        .then((res) => {
          return res.data.data;
        })
    },
  }
}
</script>

<style scoped lang="scss">

</style>